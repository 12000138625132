<template>
  <div class='account-list-container'>
<!--    header -->
    <b-row>
       <b-col cols="">
         <div class="count-account">
           <p class="count__account-text">全ユーザー数<strong class="ml-1 count__account-num">{{ totalPage }}</strong></p>
         </div>
           <div class="list-size mb-3 mt-1" style="width: 160px">
             <v-select
                 v-model="sizePage"
                 :clearable="false"
                 :options="optionsSelectPage"
             />
           </div>
         </b-col>
      <!--                search-->
       <b-col>
         <div class="header-menu-bar col-12">
             <validation-observer v-slot="{invalid}">
               <b-form class="mt-2" @submit.prevent>
                 <validation-provider
                     #default="{ errors }"
                     name="検索"
                     vid="search"
                 >
                   <b-input-group class="search-input" @keyup.enter="searchQuery" size="md">
                     <b-form-input :formatter="limitSearch" v-model="keywordSearch" placeholder="ユーザー検索"></b-form-input>
                     <b-button :disabled="invalid" @click="searchQuery" class="btn btn-search"><feather-icon icon="SearchIcon" size="14" /></b-button>
                   </b-input-group>
                   <small class="text-danger">{{ errors[0] }}</small>
                 </validation-provider>
               </b-form>
             </validation-observer>
           <b-button class="btn__open-modal btn-create-modal h-40 mt-2" @click="changeDialogCreate">新規ユーザー追加</b-button>
         </div>
       </b-col>
<!--      main table -->
       <b-col cols="12 main-table">
         <div class="overflow-auto" >
           <b-table
               id="account-table"
               hover
               responsive
               :items="accounts"
               :fields="fields"
               :per-page="0"
               :current-page="currentPage"
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               first-number
               thead-tr-class="header-split"
               tbody-tr-class="table-white-line"
               show-empty
           >
             <template #cell(action)="row">
               <feather-icon
                   icon="MoreVerticalIcon"
                   size="20"
                   :id="row.item.login_id"
                   @click="toolTip= !toolTip"
               />
               <b-popover triggers="click blur" custom-class="action_popover" placement="right" v-model="toolTip" :target="row.item.login_id">
                 <p class="action action-update" @click="getAccountUpdate(row.item)
                  ">
                   <feather-icon class="icon-action" icon="PenToolIcon" size="18" />編集
                 </p>
                 <p class="action action-destroy" @click="getAccountDelete(row.item.id)">
                   <feather-icon class="icon-action" icon="TrashIcon" size="18" />削除
                 </p>
               </b-popover>
             </template>
             <template #cell(role)="data">
               <p v-for="role in options.filter(role=>data.item.role_id.indexOf(role.value)!== -1)">
                 {{  role.text }}
               </p>
             </template>
             <template #empty>
               <p class="text-center">データが見つかりません。</p>
             </template>
           </b-table>
           <div class="pagination float-right">
             <b-pagination
                 v-model="currentPage"
                 :total-rows="totalPage"
                 :per-page="perPage"
                 @change="pageChangeHandler"
             ></b-pagination>
           </div>
         </div>
       </b-col>
     </b-row>

<!--    Create account-->
    <div>
      <Dialog
          :dialog="dialogCreateUser"
          :showCancelButton="false"
          :showOkButton="false"
          :hideHeader="true"
          :centered="true"
      >
        <template #header>
          <h5 class="header-modal-title">新規アカウント追加</h5>
        </template>
        <template #content>
          <div class="content-modal-create pt-1">
            <div class="form-create">
              <validation-observer  v-slot="{invalid}" ref="simpleRulesCreate">
                <b-form class="mt-2" @submit.prevent>

                  <!--          field id-->
                  <validation-provider
                      #default="{ errors }"
                      name="メールアドレス"
                      vid="login_id"
                  >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="メールアドレス"
                        label-for="input-horizontal"
                    >
                      <b-form-input :class="errors[0] ?'errors__validated' :''"
                                    autoComplete="new-login_id" placeholder="メールアドレス"
                                    autocomplete="off" v-model="accountCreate.login_id"
                                    size="sm" id="input-horizontal">
                      </b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!--          field user name -->
                  <validation-provider
                      #default="{ errors }"
                      name="ユーザー名"
                      vid="name"
                  >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="ユーザー名"
                        label-for="ユーザー名"
                    >
                      <b-form-input :class="errors[0] ?'errors__validated' :''" placeholder="ユーザー名を入力" v-model="accountCreate.name" size="sm" id="ユーザー名"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!--          field password-->
                  <validation-provider
                      #default="{ errors }"
                      name="パスワード"
                      vid="password"
                  >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="パスワード"
                        label-for="パスワード"
                    >
                      <b-form-input  :class="errors[0] ?'errors__validated' :''" autoComplete="new-password"  type="password"  placeholder="パスワードを設定" v-model="accountCreate.password" size="sm" id="パスワード"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!--          field confirm password-->
                  <validation-provider
                      #default="{ errors }"
                      name="パスワード（確認)"
                      vid="confirmed"
                  >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="パスワード（確認）"
                        label-for="confirmPw"
                    >
                      <b-form-input :class="errors[0] ?'errors__validated' :''" type="password" v-model="accountCreate.confirmed" placeholder="パスワードを設定（確認）"  size="sm" id="confirmPw"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!--                field role -->
                  <validation-provider
                      #default="{ errors }"
                      name="管理者区分"
                      vid="role_id"
                  >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="管理者区分"
                        label-for="管理者区分"
                    >
                      <v-select
                          v-model="select"
                          :clearable="false"
                          label="text"
                          :options="options"
                          id="管理者区分"
                          :class="errors[0] ?'errors__validated' :''">
                        <template #no-options>
                          検索条件に一致するデータはありません。
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <div class="footer-modal-wrap footer-modal mb-2 mt-2">
                    <b-button class="btn__cancer-modal" @click="changeDialogCreate">キャンセル</b-button>
                    <b-button :disabled="invalid" class="btn__open-modal" @click="createAccount">新規ユーザー追加</b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
<!--    update account-->
    <div>
      <Dialog
          :dialog="dialogUpdateUser"
          :showCancelButton="false"
          :showOkButton="false"
          :hideHeader="true"
          :centered="true"
      >
        <template #header>
          <h5 class="header-modal-title">アカウント情報編集</h5>
        </template>
        <template #content v-if="accountUpdate">
          <div class="content-modal-create pt-1">
            <div class="form-create">
              <validation-observer ref="simpleRulesUpdate">
                <b-form class="mt-2" @submit.prevent>
                  <!--          field id-->
                  <validation-provider
                      #default="{ errors }"
                      name="メールアドレス"
                      vid="email"
                  >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="メールアドレス"
                        label-for="input-horizontal"
                    >
                      <b-form-input :class="errors[0] ?'errors__validated' :''"  :disabled="true" placeholder="メールアドレス" v-model="accountUpdate.login_id" size="sm" id="input-horizontal"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!--          field user name -->
                  <validation-provider
                      #default="{ errors }"
                      name="ユーザー名"
                      vid="name"
                  >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="ユーザー名"
                        label-for="ユーザー名"
                    >
                      <b-form-input :class="errors[0] ?'errors__validated' :''"  placeholder="ユーザー名を入力" v-model="accountUpdate.name" size="sm" id="ユーザー名"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                      #default="{ errors }"
                      name="パスワード"
                      vid="password"
                  >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="パスワード"
                        label-for="パスワード"
                    >
                      <b-form-input :class="errors[0] ?'errors__validated' :''" type="text"  placeholder="パスワードを設定" v-model="accountUpdate.password" size="sm" id="パスワード"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <!--                field role -->
                  <validation-provider
                      #default="{ errors }"
                      name="管理者区分"
                      as="select"
                      vid="role_id"
                    >
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="9"
                        label="管理者区分"
                    >
                      <v-select
                          v-model="accountUpdate.role_id"
                          label="text"
                          :clearable="false"
                          :options="options"
                          @input="changeGetIdRole(accountUpdate.role_id)"
                          :class="errors[0] ?'errors' :''"
                      >
                        <template #no-options>
                          検索条件に一致するデータはありません。
                        </template>
                      </v-select>
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div class="footer-modal-wrap footer-modal mt-3">
                    <b-button class="btn__cancer-modal" @click="dialogUpdateUser=false;">キャンセル</b-button>
                    <b-button @click="updateAccount(accountUpdate.id)"  class="btn__open-modal">更新</b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </template>
      </Dialog>
    </div>

<!--    delete account-->
    <Dialog
        v-if="accountDelete"
        :dialog="dialogDeleteUser"
        :showCancelButton="false"
        :showOkButton="false"
        :hideHeader="true"
        :centered="true"
        :size="'md'"
    >
      <template #header>
        <h5 class="header-modal-title">アカウント削除</h5>
      </template>
      <template #content>
        <p>メールアドレス（{{accountDelete.login_id}}）を削除します。よろしいですか？</p>
        <div class="footer-modal">
          <div class="footer-modal-wrap">
            <b-button class="btn__cancer-modal" @click="dialogDeleteUser = false">キャンセル</b-button>
            <b-button class="btn__delete-ok" @click="destroyAccount(accountDelete.id)">削除</b-button>
          </div>
        </div>
      </template>
    </dialog>
  </div>
</template>

<script>
import {
  BButton,
  BButtonToolbar,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BPopover,
  BRow,
  BTable,
} from "bootstrap-vue";
import Dialog from "@core/components/dialog/Dialog";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {confirmed, required} from "@validations";
import {apiService} from "@/services/api.service";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import {STATUS} from "@/constant/constant";
export default {
  name: 'AccountList',
  props: [],
  components:{
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
    BPopover,
    Dialog,
    BPagination,
    BForm,
    BFormSelect,
    BButtonToolbar,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      keyWordSearch : "",
      dialogDeleteUser :false,
      dialogUpdateUser:false,
      dialogCreateUser:false,
      headerBgVariant:"white",
      toolTip:false,
      confirmedAccDelete:false,
      required,
      confirmed,
      keywordSearch:'',
      accounts:[],
      accountCreate:{},
      accountDelete:null,
      fields: [
        { key: 'login_id', label: 'メールアドレス'},
        { key: 'name', label: 'ユーザー名'},
        { key: 'role', label: '管理者区分'},
        { key: 'action', label: ''}
      ],
      options:[],
      accountUpdate:null,
      select: null,
      currentPage:1,
      perPage:'',
      totalPage:'',
      sortBy:'',
      sortDesc:false,
      totalAccounts:'',
      sizePage : 25,
      optionsSelectPage: [10,25,50],
    }
  },
  methods: {
    // change dialog
    changeDialogCreate(){
      this.select=[];
      if (this.accountCreate){
        this.accountCreate = {}
      }
      this.dialogCreateUser = ! this.dialogCreateUser;
    },
    changeDialogUpdate(){
      this.dialogUpdateUser = ! this.dialogUpdateUser
    },
    changeDialogDelete(){
      this.dialogDeleteUser = ! this.dialogDeleteUser
    },
    // get list account
    async getListAccountAdmin(page=''){
      this.startLoading();
      try{
        let res = await apiService.get(`/manage-admin?page=${page}&size=${this.sizePage}`);
        if ( res.data.code === STATUS.SUCCESS ){
          this.accounts = res.data.data;
          this.perPage = res.data.pagination.per_page;
          this.currentPage = res.data.pagination.current_page;
          this.totalPage = res.data.pagination.total;
          this.totalAccounts = res.data.pagination.total;
        } else this.notifyError(res.data.message);
      }catch (e){
        this.notifyError(e.data.message)
      }finally {
        this.endLoading();
      }
    },
    async createAccount(){
      // accountCreate.role_id must be converted to value only ([1,2])]
      this.startLoading();
      this.$refs.simpleRulesCreate.reset();
      try {
        if (this.select.value){
          let role_id = [];
          role_id.push(this.select.value);
          this.accountCreate.role_id = role_id;
        }
        let res = await apiService.post('/manage-admin/',this.accountCreate);
        if (res.data.code === STATUS.SUCCESS && res.data){
          this.dialogCreateUser = false;
          await this.getListAccountAdmin();
          this.accountCreate={};
          this.confirmed='';
          this.select='';
          this.notifySuccess(STATUS.MESSENGER_CREATE_SUCCESS);
        }else if (res.data.code === STATUS.BAD_REQUEST){
          console.log(res.data.data);
          this.$refs.simpleRulesCreate.setErrors(res.data.data);
        }else this.notifyError(res.data.message);
      }catch (e){
        this.notifyError(e.data.message)
      }finally {
        this.endLoading();
      }
    },

    //update account
    async getAccountUpdate(account){
      this.accountUpdate = Object.assign({},account);
      this.accountUpdate.role_id  = this.options.filter(role=>this.accountUpdate.role_id.indexOf(role.value)!== -1)
      if (this.accountUpdate){
        this.changeDialogUpdate();
      }
    },
    async updateAccount(id){
      this.startLoading();
      this.$refs.simpleRulesUpdate.reset();
      try {
        let accountUpdateClone = Object.assign({}, this.accountUpdate);
        if (this.accountUpdate.role_id){
          let adminRole = [];
          if (Array.isArray(this.accountUpdate.role_id)){
            adminRole.push(this.accountUpdate.role_id[0].value);
            accountUpdateClone.role_id = adminRole
          }else {
            adminRole.push(this.accountUpdate.role_id.value);
            accountUpdateClone.role_id = adminRole;
          }
        }
        let res = await apiService.put(`/manage-admin/${id}`, accountUpdateClone)
        if (res.data.code === STATUS.SUCCESS && res.data) {
          this.changeDialogUpdate();
          await this.getListAccountAdmin();
          this.notifySuccess(STATUS.MESSENGER_UPDATE_SUCCESS);
        } else if (res.data.code === STATUS.BAD_REQUEST) {
          this.$refs.simpleRulesUpdate.setErrors(res.data.data);
        } else this.notifyError(res.data.message);
      }catch (e){
        this.notifyError(e.data.message)
      }finally {
        this.endLoading();
      }
    },
    // delete account
    //get info account delete
    getAccountDelete(id){
      this.accountDelete = this.accounts.find(account=>account.id === id);
      if (this.accountDelete){
        this.changeDialogDelete();
      }
    },
    async destroyAccount(id){
      this.startLoading();
      try {
        let res = await apiService.delete('/manage-admin/'+id);
        if (res.data.code === STATUS.SUCCESS && res.data){
          this.changeDialogDelete();
          this.getListAccountAdmin();
          this.notifySuccess(STATUS.MESSENGER_DELETE_SUCCESS);
        }else {
          this.notifyError(res.data.message);
        }
      }catch (e) {
        this.notifyError(e.data.message)
      }finally {
        this.endLoading();
      }
    },
    //get role
    async getRoles(){
      let res = await apiService.get('/get-role/');
      let roles = res.data.data;
      if (res.data.code === STATUS.SUCCESS && res.data){
        roles.forEach(role=>{
          let listRole = {
            value: role?.id,
            text: role?.role_name
          }
          this.options.push(listRole);
        })
      }else{
        this.notifyError(res.data.message);
      }
    },
    //
    changeGetIdRole(roles){
      if (roles){
        return roles.value;
      }
    },
    //search
    async searchQuery(page=''){
      this.startLoading();
      try{
        let res = await apiService.get(`/manage-admin?page=${page}&keyword=${encodeURIComponent(this.keywordSearch)}&size=${this.sizePage}`);
        if (res && res.data && res.data.code === STATUS.SUCCESS){
          this.accounts = res.data.data;
          this.totalPage = res.data.pagination.total;
          this.totalAccounts = res.data.pagination.total;
          this.currentPage = res.data.pagination.current_page;
        }else{
          this.notifyError(res.data.message);
        }
      }catch (e){
        this.notifyError(e.data.message)
      }finally {
        this.endLoading();
      }
    },
    limitSearch(e) {
      return String(e).substring(0, 20);
    },
    pageChangeHandler(page){
      if (this.keywordSearch){
        this.searchQuery(page).catch(err => console.log(err))
      }else{
        this.getListAccountAdmin(page).catch(err => console.log(err))
      }
    }
  },
  mounted() {
    this.getListAccountAdmin();
    this.getRoles();
  },
  watch:{
    sizePage(){
      if (this.keywordSearch){
        this.keywordSearch = ""
      }
      this.getListAccountAdmin();
    }
  }
}
</script>

<style lang='scss' scoped>
//@import '@core/scss/vue/libs/vue-select.scss';
.count-account{
  font-size: 16px;
  .count__account-text{
      color: #6F767E;
      margin-bottom: 0;
  }
  .count__account-num{
      color:#000000;
  }
}
.main-table{
  margin-top: -20px;
}
.btn{
  background-color: #377DFF !important;
  border: none;
  border-radius: 6px;
  color:#ffffff;

}
.btn-search{
  margin-left: -7px;
  margin-right: 20px;
}
.header-menu-bar{
  display: inline-flex;
  position: relative;
  bottom: 80px;
  right: 0;
  padding: 0;
  justify-content: flex-end;
}
.action_popover{
  margin-top: 10px !important ;
}
.action_popover{
  .action{
    cursor: pointer;
    padding-top: 10px;
  }
  .icon-action{
    margin-right: 10px;
  }
}
.action-destroy{
  color: #DC0000;
  border-top: 1px solid #C7C7C7;
}
//style dialog create
.content-modal-create{
  padding:0 15px;
}
.content-modal-create .form-create{
  border-top: 1px solid #E0E0E0;
}
.footer-modal-wrap{
  .btn__cancer-modal{
    background-color: #ffffff !important;
    color: #000000 !important;
    margin-right: 10px;
    padding-top: 9px;
    border: 1px solid #EDEDED !important;
  }
  .btn__delete-ok{
    background-color: #DC0000!important;
  }
}
.btn__open-modal{
  background-color: #377DFF !important;
  border: none;
}
.btn__open-modal:hover{
  opacity: 0.8;
}
.footer-modal-wrap{
  display: flex;
  justify-content: flex-end;
  .btn__cancer-modal{
    background-color: #ffffff !important;
    color: #000000 !important;
    margin-right: 10px;
    padding-top: 9px;
    border: 1px solid #EDEDED !important;
  }
}
.header-modal-title{
  font-weight: 600;
  width: 100%;
  color:#000000;
  padding: 15px 15px 0 15px;
  font-size: 16px;
}
</style>
